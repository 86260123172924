<template>
    <div class="row">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Attendance </h2>
                    </div>
                </div>            
            </div> 
            <div class="row mt-2" v-if="!isLoad">
                <div v-if="courseAttendances.length || userAttendance" class="col-12">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="card shadow-xs rounded-lg border-0 pl-3 chat-wrapper scroll-bar">
                                <div class="section full mt-2 mb-2">
                                    <ul id="data-1" class="list-group list-group-flush">
                                        <li class="bg-transparent list-group-item no-icon pl-0" v-for="(item, n) in courseAttendances" :key="n">
                                            <div class="row" v-if="item.user_id">
                                                <div class="col-12">
                                                    <div v-if="item.attendance">
                                                        <figure class="avatar float-left mb-0 mx-3">
                                                            <img :src="item.photo ? item.photo : 'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'" alt="image" class="w45">
                                                            <i v-if="item.attendance.type == 1" class="feather-check-circle text-success ml-3"></i>
                                                            <i v-if="item.attendance.type == 2" class="feather-check-circle text-warning ml-3"></i>
                                                        </figure>
                                                    </div>
                                                    <div v-else>
                                                        <figure class="avatar float-left mb-0 mx-3">
                                                            <img :src="item.photo ? item.photo : 'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'" alt="image" class="w45">
                                                            <i class="feather-slash text-danger ml-3"></i>
                                                        </figure>
                                                    </div>
                                                    <h3 class="fw-700 mb-0 mt-1">
                                                        <a class="font-xsss text-grey-900 text-dark d-block">
                                                            {{item.name}} <span class="text-grey-600">({{item.status}})</span>
                                                        </a>
                                                    </h3>
                                                    <span class="d-block">{{item.email}}</span> 
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-12">
                                                    <div >
                                                        <figure class="avatar float-left mb-0 mx-3">
                                                            <img :src="'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'" alt="image" class="w45">
                                                            <i class="feather-slash text-muted ml-3"></i>
                                                        </figure>
                                                    </div>
                                                    <h3 class="fw-700 mb-0 mt-1">
                                                        <a class="font-xsss text-muted d-block">
                                                            Unknown
                                                        </a>
                                                    </h3>
                                                    <span class="d-block">Unknown</span> 
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-if="isStillLoadCourseAttendances" class="row">
                                        <div class="col-12 py-3 text-center">
                                            <div class="my-3">
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div v-if="!isLoadUserAttendance" class="card shadow-xs rounded-lg border-0 p-4 mb-4">
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-group mb-0">
                                            <input @change="changeDate()" v-model="date" type="date" class="form-control" required>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 text-center">
                                        <img :src="userAttendance.photo ? userAttendance.photo : 'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'" alt="image" class="w-25">
                                    </div>
                                </div>
                                <h4 class="font-md fw-700 text-grey-900 text-center d-block mb-2">{{userAttendance.name}}</h4>
                                <h4 class="font-xsss fw-500 text-grey-700 text-center d-block mb-0">{{userAttendance.email}}</h4> <hr>
                                <h4 class="font-xsss fw-700 text-grey-900 text-center d-block mb-0">Accumulation</h4> <hr>
                                <div class="row">
                                    <div class="col-12 mb-3 border-bottom pb-3">
                                        <table>
                                            <tr class="font-xsss text-grey-900">
                                                <td>Present</td>
                                                <td>&nbsp; : &nbsp;</td>
                                                <td>{{userAttendance.accumulate_present}}</td>
                                            </tr>
                                            <tr class="font-xsss text-grey-900">
                                                <td>Late</td>
                                                <td>&nbsp; : &nbsp;</td>
                                                <td>{{userAttendance.accumulate_absent}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <div class="row">
                                            <span class="col-12 font-xs text-grey-900 fw-700 mb-3">{{date | Date}}</span>
                                            <button v-if="!userAttendance.attendance && date == today" @click="postAttendance" class="col-12 btn btn-lg btn-block btn-success"><i class="fas fa-check mr-2"></i>Present</button>
                                            <button v-if="userAttendance.attendance" disabled class="col-12 btn btn-lg btn-block btn-success"><i class="fas fa-check mr-2"></i>Already</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card shadow-xs rounded-lg border-0 p-4 mb-4">
                                <div class="col-12 py-3 text-center">
                                    <div class="my-3">
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            today: '',
            date: '',
            data: [],
            userAttendances: [],
            courseAttendances: [],
            isLoadCourseAttendances: true,
            isStillLoadCourseAttendances: true,
            userAttendance: {},
            isLoadUserAttendance: true,
            user: {},
        }
    },
    filters:{
        Date(value) {
            if (value) {
                return moment(String(value)).format('MMMM, Do YYYY')
            }
        }
    },
    created(){
        this.date = this.getCurrentLocalDate()
        this.today = this.getCurrentLocalDate()
        this.getUserAttendanceData()
        this.getCourseAttendaceData()
    },
    methods: {
       getCurrentLocalDate() {
            // Create a new Date object for the current date and time
            const date = new Date();

            // Get the year, month, and day parts from the Date object
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
            const day = String(date.getDate()).padStart(2, '0');

            // Combine the parts into the desired format
            const formattedDate = `${year}-${month}-${day}`;

            // Return the formatted date
            return formattedDate;
        },
        async getCourseAttendaceData(){
            this.isLoadCourseAttendances = true
            this.isStillLoadCourseAttendances = true
            let queryLimit = 10;
            let queryOffset = 0;
            this.courseAttendances = [];
            const currDate = this.date || this.getCurrentLocalDate()

            while(this.isStillLoadCourseAttendances){
                await axios.get(`${process.env.VUE_APP_URL_API}/core/v2/attendance?limit=${queryLimit}&offset=${queryOffset}&slug=${this.paramsId}&date=${currDate}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.date = currDate
                    if(res.data.data.length == 0){
                        this.isStillLoadCourseAttendances = false
                    }else{
                        this.courseAttendances = [...this.courseAttendances, ...res.data.data]
                        queryOffset = queryOffset + queryLimit
                        this.isLoadCourseAttendances = false
                    }
                })
            }

            console.log("ATTENDANCES", this.courseAttendances)
        },
        async getUserAttendanceData(){
            this.isLoadUserAttendance = true
            const currDate = this.date || this.getCurrentLocalDate()
            await axios.get(`${process.env.VUE_APP_URL_API}/core/v2/attendance/me?slug=${this.paramsId}&date=${currDate}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.userAttendance = res.data.data
                this.isLoadUserAttendance = false
                this.date = currDate
                this.isLoad = false
            })
        },
        async postAttendance(){
            var data = {
                slug: this.paramsId
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/attendance`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.getCourseAttendaceData()
                    this.getUserAttendanceData()
                    this.$swal({
                        toast: true,
                        title: 'Attendance',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Attendance',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        changeDate(){
            this.isLoad = true
            this.isLoadCourseAttendances = true
            this.isLoadUserAttendance = true
            this.isStillLoadCourseAttendances = true
            this.getCourseAttendaceData()
            this.getUserAttendanceData()
        },
    }
}
</script>

<style scoped>
.isactive{
    background-color: #FCC13E;
    color: black;
}
.inactive{
    background-color: white;
    color: black;
}
</style>